import { gql } from "@apollo/client";

const GET_NORMATIVS = gql`
query{
  normativs {
      NormId
      RegionID
      DBComment
      Mask
      NrspFile
      CatFile
      Overhd_OrderDetails
      Profit_OrderDetails
      RegDate
      RegionName
      Type
  }
      
  }
`;

export {GET_NORMATIVS}