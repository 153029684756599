/* eslint-disable eqeqeq */

import {IInputSmeta, IInputTable, chapters_col_enum, indexes_col_enum, k_col_enum, positions_col_enum, props_row_enum, resources_col_enum, trans_col_enum, vrs_col_enum } from "../types/smeta_tables";
import { AddParamrow, GetXMLNodeContent, GetXMLNodes, SetValue } from "./UtilsTbls";

let gge_tables:IInputTable[]=[]
let gge_smeta:IInputSmeta
export const CreateAsGGE =  (source:string, filename:string, normativs:any[]) => {
    
    try{
        source=source.replaceAll('×','x')
        source=source.replaceAll('⌀','')
        source=source.replaceAll('⁰','')
        const CreateSmeta=( xml:any)=>{
            const tblparams: IInputTable={name:"p",rows:[]}
            let tmpl_param_row:any[] = ['']
            const tblchapters: IInputTable={name:"1",rows:[]}
            let tmpl_chapter_row:any[] = ['']
            const tblpositions: IInputTable={name:"2",rows:[]}
            let tmpl_position_row:any[] = ['']
            const tblress: IInputTable={name:"3",rows:[]}
            let tmpl_ress_row:any[] = ['']
            const tbltrans: IInputTable={name:"4",rows:[]}
            let tmpl_trans_row:any[] = ['']
            const tblvrs: IInputTable={name:"vr",rows:[]}
            let tmpl_vr_row:any[] = ['']
            const tblinds: IInputTable={name:"i",rows:[]}
            let tmpl_ind_row:any[] = ['']
            const tblk: IInputTable={name:"k",rows:[]}
            let tmpl_k_row:any[] = ['']
            const normativ=normativs.filter(item=>((item.NormId==GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num"))&&(item.Mask=='')))
            normativ.forEach(item=>{
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.RegionID,item.RegionID)
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.RegionName,item.RegionName)
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.NrspFile,item.NrspFile)
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.CatFile,item.CatFile)
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_OrderDetails,item.Overhd_OrderDetails)
                AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_OrderDetails,item.Profit_OrderDetails)
            })
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.ComposeFIO, GetXMLNodeContent(xml,"Object/Estimate/Signatures/ComposeFIO"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.VerifyFIO, GetXMLNodeContent(xml,"Object/Estimate/Signatures/VerifyFIO"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.AdmRegionCode, GetXMLNodeContent(xml,"Object/Region/Code"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_BaseName, GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Name"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_OrderDetails, GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Orders"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_RegNumber, GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Num"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_RegDate, 
                (GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Date/Year")}`
                )
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Overhd_BaseType, (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Overheads/Type")
                    if (Value=="Справочная информация") return "INFO"
                    Value=Value.replace('Г','G')
                    .replace('Э','E')
                    .replace('С','S')
                    .replace('Н','N')
                    .replace('Ф','F')
                    .replace('Т','T')
                    .replace('И','I')
                return Value
                })())
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_BaseName, GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Name"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_RegNumber, GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Num"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_OrderDetails, GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Orders"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_RegDate, 
                (GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Date/Year")}`
                )
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.Profit_BaseType, (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Profits/Type")
                    if (Value=="Справочная информация") return "INFO"
                    Value=Value.replace('Г','G')
                    .replace('Э','E')
                    .replace('С','S')
                    .replace('Н','N')
                    .replace('Ф','F')
                    .replace('Т','T')
                    .replace('И','I')
                return Value
                })())
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.BaseType, (()=>{
                let Value=GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Type")
                if (Value=="Справочная информация") return "INFO"
                Value=Value.replace('Г','G')
                .replace('Э','E')
                .replace('С','S')
                .replace('Н','N')
                .replace('Ф','F')
                .replace('Т','T')
                .replace('И','I')
                return Value
            })())
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.BaseName, GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Name"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.OrderDetails, GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Orders"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.RegNumber, GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num"))
            AddParamrow(tblparams, tmpl_param_row, props_row_enum.RegDate, 
                (GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Day")=='')?'':
                `${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Day"))
                }.${((value:string)=>(`${'0'.repeat(2-value.length)}${value}`))(GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Month"))
                }.${GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Date/Year")}`
                )
            const Chapters=GetXMLNodes(xml,"Object/Estimate/Sections/Section")
            
            Chapters.forEach((item:any, index:number)=>{ 
                let chapter_row =[...tmpl_chapter_row]
                chapter_row[chapters_col_enum.Caption]=GetXMLNodeContent(item,"Name")
                chapter_row[chapters_col_enum.Row]=String(tblchapters.rows.length+1)
                tblchapters.rows.push(
                    chapter_row
                )
                const Positions=GetXMLNodes(item,"Items/Item")
                Positions.forEach((itm:any)=>{ 
                    let position_row =[...tmpl_position_row]
                    SetValue(position_row,positions_col_enum.Chapter,String(index+1))
                    SetValue(position_row,positions_col_enum.Row,String(tblpositions.rows.length+1))
                    console.log(GetXMLNodeContent(itm,'Cost/Prefix'))
                   const code:string =`${GetXMLNodeContent(itm,'Cost/Prefix')}${
                    GetXMLNodeContent(itm,'Cost/Code',
                    GetXMLNodeContent(itm,'Transport/Code',
                    GetXMLNodeContent(itm,'Equipment/Code',
                    GetXMLNodeContent(itm,'Material/Code'))))}`
                    if (GetXMLNodeContent(itm,'Cost/Prefix')!==''){
                        const dbcomments= normativs.filter(item=>((item.NormId==GetXMLNodeContent(xml,"Object/Estimate/Legal/Main/Num"))&&(code.startsWith(item.Mask)))).sort(item.Mask)
                        dbcomments.forEach(nrm=>{
                        SetValue(position_row,positions_col_enum.DBComment,nrm.DBComment)
                        })
                    }
                   
                    SetValue(position_row,positions_col_enum.Number,
                        GetXMLNodeContent(itm,'Cost/Num',
                        GetXMLNodeContent(itm,'Transport/Num',
                        GetXMLNodeContent(itm,'Equipment/Num',
                        GetXMLNodeContent(itm,'Material/Num')))))
                    SetValue(position_row,positions_col_enum.Code,code)
                    SetValue(position_row,positions_col_enum.Caption,
                        GetXMLNodeContent(itm,'Cost/Name',
                        GetXMLNodeContent(itm,'Transport/Name',
                        GetXMLNodeContent(itm,'Equipment/Name', 
                        GetXMLNodeContent(itm,'Material/Name')))))
                    SetValue(position_row,positions_col_enum.Units,
                        GetXMLNodeContent(itm,'Cost/Unit',
                        GetXMLNodeContent(itm,'Transport/Unit',
                        GetXMLNodeContent(itm,'Equipment/Unit', 
                        GetXMLNodeContent(itm,'Material/Unit')))))
                    SetValue(position_row,positions_col_enum.Vr2001,
                            (()=>{
                                let curvr=[...tmpl_vr_row]
                                SetValue(curvr,vrs_col_enum.Row,String(tblvrs.rows.length+1))
                                SetValue(curvr,vrs_col_enum.Grop_Caption, GetXMLNodeContent(itm,'WorkType'))
                                SetValue(curvr,vrs_col_enum.NrCode, GetXMLNodeContent(itm,'Overheads/Reason'))
                                SetValue(curvr,vrs_col_enum.NaclMask, GetXMLNodeContent(itm,'Overheads/Target',"ФОТ"))
                                SetValue(curvr,vrs_col_enum.Caption, GetXMLNodeContent(itm,'Overheads/Name',
                                (()=>{
                                    if (GetXMLNodeContent(itm,"Transport")) return  'Перевозка грузов'
                                    if (GetXMLNodeContent(itm,"Equipment")) return  'Оборудование'
                                    if (GetXMLNodeContent(itm,"Material")) return  'Материал по прайсу'
                                    return ''
                                })()))
                                SetValue(curvr,vrs_col_enum.Nacl, GetXMLNodeContent(itm,'Overheads/Value'))
                                SetValue(curvr,vrs_col_enum.NaclCurr, GetXMLNodeContent(itm,'Overheads/Value'))
                                SetValue(curvr,vrs_col_enum.SpCode, GetXMLNodeContent(itm,'Profits/Reason'))
                                SetValue(curvr,vrs_col_enum.PlanMask, GetXMLNodeContent(itm,'Profits/Target',"ФОТ"))
                                SetValue(curvr,vrs_col_enum.Plan, GetXMLNodeContent(itm,'Profits/Value'))
                                SetValue(curvr,vrs_col_enum.PlanCurr, GetXMLNodeContent(itm,'Profits/Value'))
                                SetValue(curvr,vrs_col_enum.Category, GetXMLNodeContent(itm,'Overheads/Name',
                                (()=>{
                                    if (GetXMLNodeContent(itm,"Transport")) return  'Transportation'
                                    if (GetXMLNodeContent(itm,"Equipment")) return  'Equipment'
                                    if (GetXMLNodeContent(itm,'WorkType').startsWith('Монтажные')) return  'Mounting'
                                    return 'Construction'
                                })()))
                                SetValue(curvr,vrs_col_enum.OsColumn,
                                   (()=>{
                                    const Equipment= GetXMLNodeContent(itm,'Equipment')
                                    if (Equipment) return 'О'
                                    if (GetXMLNodeContent(itm,'WorkType').startsWith('Монтажные')) return 'М'
                                    return ''
                                   })() )
                                let rowindex=tblvrs.rows.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==curvr[i])&&(i!==vrs_col_enum.Row)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                })
                                if (rowindex<0) {
                                    rowindex=tblvrs.rows.length+1
                                    tblvrs.rows.push(curvr)                                    
                                }else rowindex++
                                return String(rowindex)
                            })())

                    SetValue(position_row,positions_col_enum.PriceLevel,
                        (()=>{
                            if (GetXMLNodeContent(itm,'Material')) return "Curr"
                            return "2001"
                        })())
                    SetValue(position_row,positions_col_enum.Quantity,
                        GetXMLNodeContent(itm,'Cost/QuantityTotal',
                        GetXMLNodeContent(itm,'Transport/ConsumptionTotal',
                        GetXMLNodeContent(itm,'Equipment/ConsumptionTotal', 
                        GetXMLNodeContent(itm,'Material/ConsumptionTotal')))
                        ))
                    SetValue(position_row,positions_col_enum.Options,
                        (()=>{
                            let result=''
                            if (GetXMLNodes(itm,'Material').length>0) result=`NotInNB`
                            if (GetXMLNodes(itm,'Equipment/Index').length>0) result=`${result} ForcedResIdx`
                            return result.trim()
                        })())
                    SetValue(position_row,positions_col_enum.BMT,GetXMLNodeContent(itm,'Equipment/PricePerUnitBase'))
                    SetValue(position_row,positions_col_enum.CMT,GetXMLNodeContent(itm,'Equipment/PricePerUnitCur',
                    GetXMLNodeContent(itm,'Material/PricePerUnitCur',
                    GetXMLNodeContent(itm,'Transport/PricePerUnitCur'))))
                    SetValue(position_row,positions_col_enum.SlaveRow,(()=>{
                         if (GetXMLNodes(itm,'Material').length>0) return 'Yes'
                        return ''
                    })())

                    SetValue(position_row,positions_col_enum.AuxMat,(()=>{
                        const Amendments= GetXMLNodeContent(itm,'Amendments')
                        if (Amendments) return String(Math.round(Number(Amendments)*100/Number(GetXMLNodeContent(itm,'Cost/ResourcesInternal/Worker/PriceTotalCur'))))
                       return ''
                   })())
                   SetValue(position_row,positions_col_enum.IndexCode,
                    (()=>{
                        const Index =GetXMLNodes(itm,'Equipment/Index')[0]
                        let ind=[...tmpl_ind_row]
                        if (Index){  
                            SetValue(ind,indexes_col_enum.Row,String(tblinds.rows.length+1))
                            SetValue(ind,indexes_col_enum.Type, 'Pos')
                            SetValue(ind,indexes_col_enum.Caption, GetXMLNodeContent(Index,'Name'))
                            SetValue(ind,indexes_col_enum.Code, GetXMLNodeContent(Index,'Reason'))
                            SetValue(ind,indexes_col_enum.MT, GetXMLNodeContent(Index,'Value/IndexValue'))                       
                            let rowindex=tblinds.rows.findIndex(item=>{
                            let result=true
                            for (var i = 0; i < item.length; i++) {
                                if ((item[i]!==ind[i])&&(i!==indexes_col_enum.Row)) {
                                    result=false
                                    break
                                }
                            }
                            return result
                            })
                            if (rowindex<0) {
                                rowindex=tblinds.rows.length+1
                                tblinds.rows.push(ind)                                    
                            } else rowindex++
                            return String(rowindex)
                        }
                        return ''
                    })())
                    const OverheadsCoefficient=GetXMLNodes(itm,'Overheads/Coefficients/Coefficient')
                    let ovk=''
                    OverheadsCoefficient.forEach((citm:any)=>{
                        ovk=`${ovk}${ (ovk.length>0)?'*':'' }${GetXMLNodeContent(citm,'Value')}`
                    })
                    SetValue(position_row,positions_col_enum.NKB,ovk)
                    SetValue(position_row,positions_col_enum.NKI,ovk)
                    SetValue(position_row,positions_col_enum.NKR,ovk)
                    const ProfitsCoefficient=GetXMLNodes(itm,'Profits/Coefficients/Coefficient')
                    let pk=''
                    ProfitsCoefficient.forEach((citm:any)=>{
                        pk=`${pk}${ (pk.length>0)?'*':'' }${GetXMLNodeContent(citm,'Value')}`
                    })
                    SetValue(position_row,positions_col_enum.PNB,pk)
                    SetValue(position_row,positions_col_enum.PKI,pk)
                    SetValue(position_row,positions_col_enum.PKR,pk)
                    
                    const Koeffs=GetXMLNodes(itm,'Cost/Coefficients/Coefficient')
                    Koeffs.forEach((k:number,i:number)=>{
                        let k_row =[...tmpl_k_row]
                        const Values=GetXMLNodes(k,'Values/Value')
                        let Options='Base Curr'
                        Values.forEach((value:any)=>{
                            switch(GetXMLNodeContent(value,"Target")) {
                                case 'ЭМ':  
                                    SetValue(k_row,k_col_enum.Value_EM,GetXMLNodeContent(value,"CoefValue"))
                                    Options=`${Options} EmQty`
                                    break
                                case 'ЗТ':  
                                    SetValue(k_row,k_col_enum.Value_OZ,GetXMLNodeContent(value,"CoefValue"))
                                    Options=`${Options} OzpTz`
                                    break
                                case 'ЗТМ':  
                                    SetValue(k_row,k_col_enum.Value_ZM,GetXMLNodeContent(value,"CoefValue"))
                                    Options=`${Options} ZpmTz`
                                    break
                                case 'ОТ':  
                                    SetValue(k_row,k_col_enum.Value_OZ,GetXMLNodeContent(value,"CoefValue"))
                                    break
                                case 'ОТМ':  
                                    SetValue(k_row,k_col_enum.Value_ZM,GetXMLNodeContent(value,"CoefValue"))
                                    break
                                case 'МАТ': 
                                    SetValue(k_row,k_col_enum.Value_MT,GetXMLNodeContent(value,"CoefValue")) 
                                    Options=`${Options} MatQty`
                                    break
                                case 'ОБ':  
                                    SetValue(k_row,k_col_enum.Value_MT,GetXMLNodeContent(value,"CoefValue"))
                                    Options=`${Options} MatQty`
                                    break
                              }
                        })
                        SetValue(k_row,k_col_enum.Caption,GetXMLNodeContent(k,"Name"))
                        SetValue(k_row,k_col_enum.Code,GetXMLNodeContent(k,"Reason"))
                        SetValue(k_row,k_col_enum.Options,Options)
                        SetValue(k_row,k_col_enum.Position,String(tblpositions.rows.length+1))
                        SetValue(k_row,k_col_enum.Level,String(i+1))
                        tblk.rows.push(k_row)    
                    })
                    const Tzrs=GetXMLNodes(itm,"Cost/ResourcesInternal/Worker")
                    Tzrs.forEach((res:any)=>{
                        let res_row =[...tmpl_ress_row]
                        
                        SetValue(res_row,resources_col_enum.Row,String(tblress.rows.length+1))
                        SetValue(res_row,resources_col_enum.Type,`Tzr`)
                        SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                        SetValue(res_row,resources_col_enum.Code,GetXMLNodeContent(res,'Code'))
                        SetValue(res_row,resources_col_enum.Caption,GetXMLNodeContent(res,'Name'))
                        SetValue(res_row,resources_col_enum.Quantity,GetXMLNodeContent(res,'Consumption'))
                        SetValue(res_row,resources_col_enum.Units,GetXMLNodeContent(res,'Unit'))
                        SetValue(res_row,resources_col_enum.WorkClass,(()=>{
                            const code:string=GetXMLNodeContent(res,'Code')
                            try{
                                const parts=code.split('-')
                                if (parts.length==3){
                                    return String(Number(parts[2])/10)
                                }
                            }catch{}                            
                            return ''
                        })())
                        SetValue(res_row,resources_col_enum.PriceCurrValue,GetXMLNodeContent(res,'PricePerUnitCur'))
                        tblress.rows.push(res_row)
                    })
                    const Mchs=GetXMLNodes(itm,"Cost/ResourcesInternal/Machine")
                    let tzm:number=0
                    let mchpos:any[]=[]
                    Mchs.forEach((res:any)=>{
                        let res_row =[...tmpl_ress_row]
                        SetValue(res_row,resources_col_enum.Row,String(mchpos.length+1))
                        SetValue(res_row,resources_col_enum.Type,`Mch`)
                        SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                        SetValue(res_row,resources_col_enum.Code,GetXMLNodeContent(res,'Code'))
                        SetValue(res_row,resources_col_enum.Caption,GetXMLNodeContent(res,'Name'))
                        SetValue(res_row,resources_col_enum.Quantity,GetXMLNodeContent(res,'Consumption'))
                        SetValue(res_row,resources_col_enum.Units,GetXMLNodeContent(res,'Unit'))
                        SetValue(res_row,resources_col_enum.PriceBaseValue,GetXMLNodeContent(res,'PricePerUnitBase'))
                        SetValue(res_row,resources_col_enum.PriceCurrValue,GetXMLNodeContent(res,'PricePerUnitCur'))
                        SetValue(res_row,resources_col_enum.TzmWorkClass,GetXMLNodeContent(res,'Machinist/Grade'))
                        SetValue(res_row,resources_col_enum.TzmPrice,GetXMLNodeContent(res,'Machinist/PricePerUnitCur'))
                        const tzme=Number(GetXMLNodeContent(res,'Machinist/Consumption'))/Number(GetXMLNodeContent(res,'Consumption'))
                        if (tzme>0){
                           SetValue(res_row,resources_col_enum.TzmQuantity,String(tzme)) 
                            tzm=tzm+tzme*Number(GetXMLNodeContent(res,'Consumption'))
                        }                        
                        SetValue(res_row,resources_col_enum.IndexCode,
                            (()=>{
                                const Index =GetXMLNodes(res,'Index')[0]
                                let ind=[...tmpl_ind_row]
                                if (Index){  
                                    SetValue(res_row,resources_col_enum.Options,'ForcedResIdx')
                                    SetValue(ind,indexes_col_enum.Row,String(tblinds.rows.length+1))
                                    SetValue(ind,indexes_col_enum.Type, 'Res')
                                    SetValue(ind,indexes_col_enum.Caption, GetXMLNodeContent(Index,'Name'))
                                    SetValue(ind,indexes_col_enum.Code, GetXMLNodeContent(Index,'Reason'))
                                    SetValue(ind,indexes_col_enum.EM, GetXMLNodeContent(Index,'Value/IndexValue'))                       
                                    let rowindex=tblinds.rows.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==ind[i])&&(i!==indexes_col_enum.Row)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                    })
                                    if (rowindex<0) {
                                        rowindex=tblinds.rows.length+1
                                        tblinds.rows.push(ind)                                    
                                    } else rowindex++
                                    return String(rowindex)
                                }
                                return ''
                            })())

                            mchpos.push(res_row)
                        //tblress.rows.push(res_row)
                    })

                    if (tzm>0){
                        let res_row =[...tmpl_ress_row]
                        SetValue(res_row,resources_col_enum.Row,String(tblress.rows.length+1))
                        SetValue(res_row,resources_col_enum.Type,`Tzm`)
                        SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                        SetValue(res_row,resources_col_enum.Caption,`Затраты труда машинистов`)
                        SetValue(res_row,resources_col_enum.Code,`2`)
                        SetValue(res_row,resources_col_enum.Units,`чел.-ч`)
                        SetValue(res_row,resources_col_enum.Quantity,String(tzm))
                        tblress.rows.push(res_row)
                    }
                    mchpos=mchpos.map((item,index)=>{
                        const row=[...item]
                        row[resources_col_enum.Row]=String(tblress.rows.length+index+1)
                        return row
                    })
                    tblress.rows.push(...mchpos)
                    const Mats=GetXMLNodes(itm,"Cost/ResourcesInternal/Material")
                    Mats.forEach((res:any)=>{
                        let res_row =[...tmpl_ress_row]
                        SetValue(res_row,resources_col_enum.Row,String(tblress.rows.length+1))
                        SetValue(res_row,resources_col_enum.Type,`Mat`)
                        SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                        SetValue(res_row,resources_col_enum.Code,GetXMLNodeContent(res,'Code'))
                        SetValue(res_row,resources_col_enum.Caption,GetXMLNodeContent(res,'Name'))
                        SetValue(res_row,resources_col_enum.Quantity,GetXMLNodeContent(res,'Consumption'))
                        SetValue(res_row,resources_col_enum.Units,GetXMLNodeContent(res,'Unit'))
                        SetValue(res_row,resources_col_enum.PriceBaseValue,GetXMLNodeContent(res,'PricePerUnitBase'))
                        SetValue(res_row,resources_col_enum.PriceCurrValue,GetXMLNodeContent(res,'PricePerUnitCur'))
                        SetValue(res_row,resources_col_enum.Mass,GetXMLNodeContent(res,'Transport/Consumption'))
                        const Trans=GetXMLNodes(res,"Transport")
                        Trans.forEach((trs:any)=>{
                            let trans_row =[...tmpl_trans_row]
                            SetValue(trans_row,trans_col_enum.Resource,String(tblress.rows.length+1))
                            SetValue(trans_row,trans_col_enum.Code,GetXMLNodeContent(trs,'Code'))
                            SetValue(trans_row,trans_col_enum.Caption,GetXMLNodeContent(trs,'Name'))
                            SetValue(trans_row,trans_col_enum.PriceCurr,GetXMLNodeContent(trs,'PricePerUnitCur'))
                            SetValue(trans_row,trans_col_enum.Units,GetXMLNodeContent(trs,'Unit'))
                            SetValue(trans_row,trans_col_enum.KC,GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue'))
                            tbltrans.rows.push(trans_row)
                        })
                        SetValue(res_row,resources_col_enum.IndexCode,
                            (()=>{
                                const Index =GetXMLNodes(res,'Index')[0]
                                let ind=[...tmpl_ind_row]
                                if (Index){  
                                    SetValue(res_row,resources_col_enum.Options,'ForcedResIdx')
                                    SetValue(ind,indexes_col_enum.Row,String(tblinds.rows.length+1))
                                    SetValue(ind,indexes_col_enum.Type, 'Res')
                                    SetValue(ind,indexes_col_enum.Caption, GetXMLNodeContent(Index,'Name'))
                                    SetValue(ind,indexes_col_enum.Code, GetXMLNodeContent(Index,'Reason'))
                                    SetValue(ind,indexes_col_enum.MT, GetXMLNodeContent(Index,'Value/IndexValue'))                       
                                    let rowindex=tblinds.rows.findIndex(item=>{
                                    let result=true
                                    for (var i = 0; i < item.length; i++) {
                                        if ((item[i]!==ind[i])&&(i!==indexes_col_enum.Row)) {
                                            result=false
                                            break
                                        }
                                    }
                                    return result
                                    })
                                    if (rowindex<0) {
                                        rowindex=tblinds.rows.length+1
                                        tblinds.rows.push(ind)                                    
                                    } else rowindex++
                                    return String(rowindex)
                                }
                                return ''
                            })())
                            tblress.rows.push(res_row)
                        })
                        const MatsNotCount=GetXMLNodes(itm,"Cost/Resources/Material")
                        MatsNotCount.forEach((res:any)=>{
                            let res_row =[...tmpl_ress_row]
                            SetValue(res_row,resources_col_enum.Row,String(tblress.rows.length+1))
                            SetValue(res_row,resources_col_enum.Type,`Mat`)
                            SetValue(res_row,resources_col_enum.Options,'NotCount')
                            SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                            SetValue(res_row,resources_col_enum.Code,GetXMLNodeContent(res,'Code'))
                            SetValue(res_row,resources_col_enum.Caption,GetXMLNodeContent(res,'Name'))
                            SetValue(res_row,resources_col_enum.Quantity,GetXMLNodeContent(res,'Consumption'))
                            SetValue(res_row,resources_col_enum.Units,GetXMLNodeContent(res,'Unit'))
                            SetValue(res_row,resources_col_enum.Mass,GetXMLNodeContent(res,'Transport/Consumption'))
                            const Trans=GetXMLNodes(res,"Transport")
                            tblress.rows.push(res_row)
                            Trans.forEach((trs:any)=>{
                                let trans_row =[...tmpl_trans_row]
                                SetValue(trans_row,trans_col_enum.Resource,String(tblress.rows.length))
                                SetValue(trans_row,trans_col_enum.Code,GetXMLNodeContent(trs,'Code'))
                                SetValue(trans_row,trans_col_enum.Caption,GetXMLNodeContent(trs,'Name'))
                                SetValue(trans_row,trans_col_enum.PriceCurr,GetXMLNodeContent(trs,'PricePerUnitCur'))
                                SetValue(trans_row,trans_col_enum.Units,GetXMLNodeContent(trs,'Unit'))
                                SetValue(trans_row,trans_col_enum.KC,GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue'))
                                tbltrans.rows.push(trans_row)
                            })
                        })
                        const MatsOut=GetXMLNodes(itm,"Resources/Material")
                        MatsOut.forEach((res:any)=>{
                            let res_row =[...tmpl_ress_row]
                            if (GetXMLNodeContent(res,'Unit')=="%"){
                                SetValue(position_row,positions_col_enum.AuxMat,GetXMLNodeContent(res,'Consumption'))
                                SetValue(position_row,positions_col_enum.AuxMatCode,GetXMLNodeContent(res,'Code'))
                            }
                            SetValue(res_row,resources_col_enum.Row,String(tblress.rows.length+1))
                            SetValue(res_row,resources_col_enum.Type,`Mat`)
                            SetValue(res_row,resources_col_enum.Position,String(tblpositions.rows.length+1))
                            SetValue(res_row,resources_col_enum.Code,GetXMLNodeContent(res,'Code'))
                            SetValue(res_row,resources_col_enum.Caption,GetXMLNodeContent(res,'Name'))
                            SetValue(res_row,resources_col_enum.Quantity,GetXMLNodeContent(res,'Consumption'))
                            SetValue(res_row,resources_col_enum.Units,GetXMLNodeContent(res,'Unit'))
                            SetValue(res_row,resources_col_enum.PriceBaseValue,GetXMLNodeContent(res,'PricePerUnitBase'))
                            SetValue(res_row,resources_col_enum.PriceCurrValue,GetXMLNodeContent(res,'PricePerUnitCur'))
                            SetValue(res_row,resources_col_enum.Mass,GetXMLNodeContent(res,'Transport/Consumption'))
                            const Trans=GetXMLNodes(res,"Transport")
                            Trans.forEach((trs:any)=>{
                                let trans_row =[...tmpl_trans_row]
                                SetValue(trans_row,trans_col_enum.Resource,String(tblress.rows.length+1))
                                SetValue(trans_row,trans_col_enum.Code,GetXMLNodeContent(trs,'Code'))
                                SetValue(trans_row,trans_col_enum.Caption,GetXMLNodeContent(trs,'Name'))
                                SetValue(trans_row,trans_col_enum.PriceCurr,GetXMLNodeContent(trs,'PricePerUnitCur'))
                                SetValue(trans_row,trans_col_enum.Units,GetXMLNodeContent(trs,'Unit'))
                                SetValue(trans_row,trans_col_enum.KC,GetXMLNodeContent(trs,'Coefficients/Final/Values/Value/CoefValue'))
                                tbltrans.rows.push(trans_row)
                            })
                            SetValue(res_row,resources_col_enum.IndexCode,
                                (()=>{
                                    const Index =GetXMLNodes(res,'Index')[0]
                                    let ind=[...tmpl_ind_row]
                                    if (Index){  
                                        SetValue(res_row,resources_col_enum.Options,'ForcedResIdx')
                                        SetValue(ind,indexes_col_enum.Row,String(tblinds.rows.length+1))
                                        SetValue(ind,indexes_col_enum.Type, 'Res')
                                        SetValue(ind,indexes_col_enum.Caption, GetXMLNodeContent(Index,'Name'))
                                        SetValue(ind,indexes_col_enum.Code, GetXMLNodeContent(Index,'Reason'))
                                        SetValue(ind,indexes_col_enum.MT, GetXMLNodeContent(Index,'Value/IndexValue'))                       
                                        let rowindex=tblinds.rows.findIndex(item=>{
                                        let result=true
                                        for (var i = 0; i < item.length; i++) {
                                            if ((item[i]!==ind[i])&&(i!==indexes_col_enum.Row)) {
                                                result=false
                                                break
                                            }
                                        }
                                        return result
                                        })
                                        if (rowindex<0) {
                                            rowindex=tblinds.rows.length+1
                                            tblinds.rows.push(ind)                                    
                                        } else rowindex++
                                        return String(rowindex)
                                    }
                                    return ''
                                })())
                            tblress.rows.push(res_row)
                        })
                 tblpositions.rows.push(position_row)     
                })
                })
                gge_smeta={
                    p:tblparams.rows,
                    vr:tblvrs.rows,
                    i:tblinds.rows,
                    k:tblk.rows,
                    wl:[],
                    lz:[],
                    0:[],
                    1:tblchapters.rows,
                    2:tblpositions.rows,
                    3:tblress.rows,
                    4:tbltrans.rows,
                    filename:filename.replace(/(\.gge)$/,'')
                }
                gge_tables.push( tblparams, 
                tblvrs,
                tblinds,
                tblk,
                {name:"wl",rows:[]},
                {name:"lz",rows:[]},
                {name:"0",rows:[]},
                tblchapters,
                tblpositions,
                tblress,
                tbltrans,
                )

        }


        const parser = new DOMParser();
        if (window.DOMParser){
            if (window.DOMParser)
            {
                const xml = parser.parseFromString(source, "text/xml");
                CreateSmeta(xml.getElementsByTagName("Construction")[0])
            }
            console.log(gge_smeta)
            return gge_smeta
        }

    }catch{

    }

    
  }

