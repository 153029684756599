import React from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
// Импортируем маршруты
import { Convert } from './convert';
import { Iul } from './iul';
import { Main } from './main';
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/link-context';
//import { IulDemo } from './iul_demo';


const uri = process.env.REACT_APP_API_URL||"https://sd.xlsmeta.ru/api";
const httpLink = new HttpLink({ uri });
const cache = new InMemoryCache();
// Определяем маршруты
const authLink = setContext((_, { headers }) => {
  return { 
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || ''
      
    }
  };
});


// configure Apollo Client
const client = new ApolloClient({ 
  link: authLink.concat(httpLink), 
  cache,
  resolvers: {}, 
  connectToDevTools: true
});
const Pages = () => {
//const dotenv = require('dotenv')
//const dotenvExpand = require('dotenv-expand')

//dotenvExpand(dotenv.config())
 // dotenv.config()
//console.log(process.env) // remove this after you've confirmed it is working
  return (
    <ApolloProvider  client={client}> 
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="iul" element={<Iul 
            demo={true}
          />} />
          <Route path="74cb86ee-ca7c-4645-bf2d-d014ed228527" element={<Iul />} />
          <Route path="convert" element={<Convert />} />
        </Routes>
      </BrowserRouter>
    </ApolloProvider>
  );
};
export default Pages;
