/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
import { IDownloadFile, IInputSmeta, chapters_col_enum, indexes_col_enum, k_col_enum, lzs_col_enum, positions_col_enum, props_col_enum, props_row_enum, resources_col_enum, trans_col_enum, vrs_col_enum, wls_col_enum } from "../types/smeta_tables"

let props
let vrs
let inds
let ks
let lzs
let vrgs
let chapters
let positions
let resources
let trans
let wls

export const AsGrandXML = (source_smeta:IInputSmeta)=>{
  let source:IDownloadFile={filename:undefined,xml:undefined}
  const GetValue=(source:any[][], row:number, col:number, defval=''):string=>{
    try{
      const result=source[row][col]
      if (result) return result
    }catch{}
    return defval
  }
  const GetAttr=(title:string, value:string)=>{
    if (value) return ` ${title}="${value}"`
    return ''
  }
  const GetGlava=(id:string)=>{
    try{return GetNode("AddZatrGlava",0,[GetAttr("Glava", id)],(()=>{
      let result=[]
      const lzs_gl=lzs.filter((item) =>(item[lzs_col_enum.Glava]==id))
      for (var i = 0; i < lzs_gl.length; i++) {
        result.push(GetNode("AddZatr",3,[
          GetAttr("Caption",GetValue(lzs_gl,i, lzs_col_enum.Caption)),
          GetAttr("Level",GetValue(lzs_gl,i, lzs_col_enum.Level)),
          GetAttr("Options",GetValue(lzs_gl,i, lzs_col_enum.Options)),
          GetAttr("Value",GetValue(lzs_gl,i, lzs_col_enum.Value)),
          GetAttr("Formula",GetValue(lzs_gl,i, lzs_col_enum.Formula)),
        ]))
      }
      return result
    })())}catch{}
      return `<AddZatrGlava Glava="${id}"/>`
  }
    const GetNode=(title:string, offset:number, attrs:string[]=[], childs:string[]=[])=>{
      if ((attrs.length==0) && (childs.length==0)) return ''
      const rep=' '.repeat(offset)
      const begin=`${rep}<${title}`
      let result =begin
      attrs.forEach((item)=>{
        result=`${result}${item}` 
      })
      if ((result==begin) &&  (childs.length==0)) return ''
      if (childs.findIndex(item=>(item))==-1) return `${result}/>`
      else result=`${result}>` 
    childs.forEach((Item)=>{
      if (Item) result=`${result}
      ${Item}`
    })
    result=`${result}
    ${' '.repeat(offset+2)}</${title}>`
    return result
  }
      props=source_smeta.p
      vrs=source_smeta.vr
      inds=source_smeta.i
      ks=source_smeta.k
      lzs=source_smeta.lz
      vrgs=source_smeta.vr.reduce((acc, item, index) => { 
        if (acc.findIndex(itm => ((itm[vrs_col_enum.Group_ID] == item[vrs_col_enum.Group_ID])&&(item[vrs_col_enum.Group_ID]!==''))||
        ((itm[vrs_col_enum.Grop_Caption] == item[vrs_col_enum.Grop_Caption])))>-1) {
              return acc; // если значение уже есть, то просто возвращаем аккумулятор
            }
        item[vrs_col_enum.Row]=index+1
      return [...acc, item]; // добавляем к аккумулятору и возвращаем новый аккумулятор
    }, []);
      chapters=source_smeta['1']
      positions=source_smeta['2']
      resources=source_smeta['3']
      trans=source_smeta['4']
      wls=source_smeta.wl
      source.filename=source_smeta.filename
     source.xml= `<?xml version="1.0" encoding="windows-1251"?>
    <Document ProgramVersion="${ GetValue(props,props_row_enum.ProgramVersion,props_col_enum.Value,'11.1.3.33976')
  }" Generator="${GetValue(props,props_row_enum.Generator,props_col_enum.Value,'GrandSmeta')}" DocumentType="{2B0470FD-477C-4359-9F34-EEBE36B7D340}">
  ${GetNode('Properties',2,[
    GetAttr('LocNum',GetValue(props,props_row_enum.LocNum,props_col_enum.Value)),
    GetAttr('Description',GetValue(props,props_row_enum.Description,props_col_enum.Value))
    ])}
      <GsDocSignatures>
        <Item Caption="Основание" ID="100"/>
        <Item Caption="Составил" ID="300" ${GetAttr('Value',GetValue(props,props_row_enum.ComposeFIO,props_col_enum.Value))}>
          <Attributes>
            <Item Caption="Должность" ID="970"/>
          </Attributes>
        </Item>
        <Item Caption="Проверил" ID="310" ${GetAttr('Value',GetValue(props,props_row_enum.VerifyFIO,props_col_enum.Value))}>
          <Attributes>
            <Item Caption="Должность" ID="970"/>
          </Attributes>
        </Item>
      </GsDocSignatures>
      <RegionalK Options="Percent Base"/>
      <TerZoneK Caption="Территориальная поправка к базе 2001г" Options="Base PzAll EmAll"/>
      ${GetNode("Koefficients",0,[],(()=>{
        let result=[]
        const smeta_ks=ks.filter((item) =>(!item[k_col_enum.Position]))
        for (var i = 0; i < smeta_ks.length; i++) {
          result.push(GetNode("K",1,[
            GetAttr('Caption',GetValue(smeta_ks,i,k_col_enum.Caption)),
            GetAttr('Code',GetValue(smeta_ks,i,k_col_enum.Code)),
            GetAttr('Level',GetValue(smeta_ks,i,k_col_enum.Level)),
            GetAttr('Options',GetValue(smeta_ks,i,k_col_enum.Options)),
            GetAttr('Value_EM',GetValue(smeta_ks,i,k_col_enum.Value_EM)),
            GetAttr('Value_MT',GetValue(smeta_ks,i,k_col_enum.Value_MT)),
            GetAttr('Value_OZ',GetValue(smeta_ks,i,k_col_enum.Value_OZ)),
            GetAttr('Value_ZM',GetValue(smeta_ks,i,k_col_enum.Value_ZM)),
          ]))
        }
        return result
      })())}
      <WinterCatalog WinterMode="None" WinterLinkMode="Table">
        <CommonWinterK/>
      </WinterCatalog>
      <RegionInfo RegionName="${ GetValue(props,props_row_enum.RegionName,props_col_enum.Value,'ФСНБ-2022 (с Изм. 1-5)')
    }" RegionID="${ GetValue(props,props_row_enum.RegionID,props_col_enum.Value,'21005')
  }" Zone84ID="1" Zone01Name="${ GetValue(props,props_row_enum.Zone01Name,props_col_enum.Value,'Базовый район')
}" Zone01ID="${ GetValue(props,props_row_enum.Zone01ID,props_col_enum.Value,'1')
}"${GetAttr("AdmRegionCode",GetValue(props,props_row_enum.AdmRegionCode,props_col_enum.Value))}/>
      <FRSN_Info${GetAttr("BaseType",GetValue(props,props_row_enum.BaseType, props_col_enum.Value, "GSN"))
    }${GetAttr("BaseName",GetValue(props,props_row_enum.BaseName, props_col_enum.Value))
  }${GetAttr("RegNumber",GetValue(props,props_row_enum.RegNumber, props_col_enum.Value, "439"))
}${GetAttr("RegDate",GetValue(props,props_row_enum.RegDate, props_col_enum.Value, "16.04.2021"))
}${GetAttr("OrderDetails",GetValue(props,props_row_enum.OrderDetails, props_col_enum.Value))}>
        <Overhd_Info BaseType="${GetValue(props,props_row_enum.Overhd_BaseType, props_col_enum.Value,'GSN') }" BaseName="${
          GetValue(props,props_row_enum.Overhd_BaseName, props_col_enum.Value, 
            "Методика по разработке и применению нормативов накладных расходов при определении сметной стоимости строительства, реконструкции, капитального ремонта, сноса объектов капитального строительства. Утверждена приказом Минстроя России от 21 декабря 2020 г. № 812/пр. Внесены изменения приказом Минстроя России от 02.09.2021 г. № 636/пр. Внесены изменения приказом Минстроя России от 26.07.2022 г. № 611/пр.")
          }" RegNumber="${GetValue(props,props_row_enum.Overhd_RegNumber, props_col_enum.Value,"377")
          }" RegDate="${GetValue(props,props_row_enum.Profit_RegDate, props_col_enum.Value,"16.04.2021")
          }" OrderDetails="${
            GetValue(props,props_row_enum.Overhd_OrderDetails, props_col_enum.Value,
              "Приказы Минстроя России № 812/пр от 21.12.2020, № 636/пр от 02.09.2021, № 611/пр от 26.07.2022")}"/>
        <Profit_Info BaseType="${GetValue(props,props_row_enum.Profit_BaseType, props_col_enum.Value,'GSN') }" BaseName="${
          GetValue(props,props_row_enum.Profit_BaseName, props_col_enum.Value, 
            "Методика по разработке и применению нормативов сметной прибыли при определении сметной стоимости строительства, реконструкции, капитального ремонта, сноса объектов капитального строительства. Утверждена приказом Минстроя России от 11 декабря 2020 г. № 774/пр. Внесены изменения приказом Минстроя России от 22.04.2022 г. № 317/пр.")
          }" RegNumber="${GetValue(props,props_row_enum.Profit_RegNumber, props_col_enum.Value,"376")
          }" RegDate="${GetValue(props,props_row_enum.Profit_RegDate, props_col_enum.Value,"16.04.2021")
          }" OrderDetails="${
            GetValue(props,props_row_enum.Profit_OrderDetails, props_col_enum.Value,
              "Приказы Минстроя России № 774/пр от 11.12.2020, № 317/пр от 22.04.2022")}"/>
      </FRSN_Info>
      <Parameters Options="AddZatr ZpmByTz 2020Mode SeparateFot MultPosK 2022Mode 2020NoRound 2022AuxMatK" BasePrices="2001" BaseCalcVrs="Vr2001" TzDigits="2" BlockRoundMode="Smart" MultKPosCalcMode="UseTotalMult" TempZone="1" TsnTempZone="1" MatDigits="4" MatRoundMode="None" PosKDigits="7" ItogOptions="SeparateVrs IgnoreZeroQty IgnoreZeroPrice" FirstItogItem="K" ItogExpandTo="Default" PropsConfigName="НС. Ресурсно-индексный метод расчета(Методики 421(в ред.557), 812(в ред.636 и 611), 774(в ред.317))">
        <CommonNK${ 
          GetAttr("CommonK",GetValue(props,props_row_enum.CommonNK_CommonK, props_col_enum.Value))
        }${ 
          GetAttr("MethodKB",GetValue(props,props_row_enum.CommonNK_MethodKB, props_col_enum.Value))
        }${ 
          GetAttr("MethodKI",GetValue(props,props_row_enum.CommonNK_MethodKI, props_col_enum.Value))
        }${ 
          GetAttr("MethodKR",GetValue(props,props_row_enum.CommonNK_MethodKR, props_col_enum.Value))
        }${ 
          GetAttr("ActiveItems",GetValue(props,props_row_enum.CommonNK_ActiveItems, props_col_enum.Value,"Vk Ck Mk"))
      }/>
      <CommonPK${ 
        GetAttr("CommonK",GetValue(props,props_row_enum.CommonPK_CommonK, props_col_enum.Value))
      }${ 
        GetAttr("MethodKB",GetValue(props,props_row_enum.CommonPK_MethodKB, props_col_enum.Value))
      }${ 
        GetAttr("MethodKI",GetValue(props,props_row_enum.CommonPK_MethodKI, props_col_enum.Value))
      }${ 
        GetAttr("MethodKR",GetValue(props,props_row_enum.CommonPK_MethodKR, props_col_enum.Value))
      }${ 
        GetAttr("ActiveItems",GetValue(props,props_row_enum.CommonPK_ActiveItems, props_col_enum.Value,"Vk Ck Mk"))
    }/>
        <MtsnNPZpm/>
      </Parameters>
      ${GetNode("Indexes",0,[
        GetAttr("IndexesMode",GetValue(props,props_row_enum.IndexesMode, props_col_enum.Value,"None")),
        GetAttr("IndexesLinkMode",GetValue(props,props_row_enum.IndexesLinkMode, props_col_enum.Value,"Default")),
        ],
        [
          GetNode("IndexesPos",1,[],
          (()=>{
            let result=[]
            try{
              const inds_pos=inds.filter((item) =>(item[indexes_col_enum.Type]=='Pos'))
              for (var i = 0; i < inds_pos.length; i++) {
                result.push(GetNode("Index",2,[
                  GetAttr("Caption",GetValue(inds_pos,i, indexes_col_enum.Caption)),
                  GetAttr("Code",(()=>{
                    const code=GetValue(inds_pos,i,indexes_col_enum.Code)
                    if (code) return code
                    return GetValue(inds_pos,i,indexes_col_enum.Row)
                  })()),
                  GetAttr("OZ",GetValue(inds_pos,i, indexes_col_enum.OZ)),
                  GetAttr("EM",GetValue(inds_pos,i, indexes_col_enum.EM)),
                  GetAttr("ZM",GetValue(inds_pos,i, indexes_col_enum.ZM)),
                  GetAttr("MT",GetValue(inds_pos,i, indexes_col_enum.MT)),
                ]))
              }
            }catch{}
            
            return result
        })()
          ),
          GetNode("IndexesRes",1,[],
          (()=>{
            let result=[]
            try {
              const inds_res=inds.filter((item) =>(item[indexes_col_enum.Type]=='Res'))
              for (var i = 0; i < inds_res.length; i++) {
                result.push(GetNode("Index",2,[
                  GetAttr("Caption",GetValue(inds_res,i, indexes_col_enum.Caption)),
                  GetAttr("Code",(()=>{
                    const code=GetValue(inds_res,i,indexes_col_enum.Code)
                    if (code) return code
                    return GetValue(inds_res,i,indexes_col_enum.Row)
                  })()),
                  GetAttr("OZ",GetValue(inds_res,i, indexes_col_enum.OZ)),
                  GetAttr("EM",GetValue(inds_res,i, indexes_col_enum.EM)),
                  GetAttr("ZM",GetValue(inds_res,i, indexes_col_enum.ZM)),
                  GetAttr("MT",GetValue(inds_res,i, indexes_col_enum.MT)),
                ]))
              }
            }catch{}
            
            return result
          })())
        ]
      )}
      <AddZatrats>
        ${GetGlava("8")}
        ${GetGlava("9")}
        ${GetGlava("10")}
        ${GetGlava("11")}
        ${GetGlava("12")}
        ${GetGlava("13")}
        ${GetGlava("14")}
        ${GetGlava("15")}
      </AddZatrats>
      <OsInfo OSChapter="1" LinkType="RS">
        <CCChapter Cons="2" Rec="2" Road="2"/>
      </OsInfo>
      <CennikAutoLoad Enabled="False" MatchFields="Code" Groups="Tzr Tzm Mch Mat" SubGroups="Standart Positions" Options="IgnoreCodePrefix" PriceTypes="PriceBase PriceCurr">
        <DocLink/>
      </CennikAutoLoad>
      <VidRab_Catalog>
        <Vids_Rab Type="Виды работ 2001г" CatFile="${GetValue(props,props_row_enum.CatFile, props_col_enum.Value,"ВР Методика 2020 и 636 и 317 и 611")}" NrspFile="${GetValue(props,props_row_enum.NrspFile, props_col_enum.Value,"НРСП Методика 2020 и 636 и 317 и 611")}">${(()=>{
          let result=''
          try{
            for (var i = 0; i < vrgs.length; i++) {
              result=`${result}
              ${GetNode('VidRab_Group',0,[
                GetAttr('Caption', GetValue(vrgs,i,vrs_col_enum.Grop_Caption)),
                GetAttr('ID', (()=>{
                  const id=GetValue(vrgs,i,vrs_col_enum.Group_ID)
                  if (id) return id
                  return GetValue(vrgs,i,vrs_col_enum.Row)
                })())
              ],
              (()=>{
                let result=[]
                try{}catch{}
                const vrs_group=vrs.filter((item) =>(item[vrs_col_enum.Grop_Caption]==vrgs[i][vrs_col_enum.Grop_Caption]))
                for (var k = 0; k < vrs_group.length; k++) {
                result.push(GetNode("Vid_Rab",3,[
                  GetAttr("Caption",GetValue(vrs_group, k, vrs_col_enum.Caption)),
                  GetAttr('ID', (()=>{
                    const id=GetValue(vrs_group,k,vrs_col_enum.ID)
                    if (id) return id
                    return GetValue(vrs_group,k,vrs_col_enum.Row)
                  })()),
                  GetAttr("OsColumn",GetValue(vrs_group, k, vrs_col_enum.OsColumn)),
                  GetAttr("Category",GetValue(vrs_group, k, vrs_col_enum.Category)),
                  GetAttr("NrCode",GetValue(vrs_group, k, vrs_col_enum.NrCode)),
                  GetAttr("SpCode",GetValue(vrs_group, k, vrs_col_enum.SpCode)),
                  GetAttr("Nacl",GetValue(vrs_group, k, vrs_col_enum.Nacl)),
                  GetAttr("Plan",GetValue(vrs_group, k, vrs_col_enum.Plan)),
                  GetAttr("NaclCurr",GetValue(vrs_group, k, vrs_col_enum.NaclCurr)),
                  GetAttr("PlanCurr",GetValue(vrs_group, k, vrs_col_enum.PlanCurr)),
                  GetAttr("NaclMask",GetValue(vrs_group, k, vrs_col_enum.NaclMask)),
                  GetAttr("PlanMask",GetValue(vrs_group, k, vrs_col_enum.PlanMask)),
                  GetAttr("NKB",GetValue(vrs_group, k, vrs_col_enum.NKB)),
                  GetAttr("PNB",GetValue(vrs_group, k, vrs_col_enum.PNB)),
                  GetAttr("NKI",GetValue(vrs_group, k, vrs_col_enum.NKI)),
                  GetAttr("PKI",GetValue(vrs_group, k, vrs_col_enum.PKI)),
                  GetAttr("NKR",GetValue(vrs_group, k, vrs_col_enum.NKR)),
                  GetAttr("PKR",GetValue(vrs_group, k, vrs_col_enum.PKR)),
                ]))
              }
              return result})()
              )}`
              }
          }catch{}
            return result
          })() 
        }
        </Vids_Rab>
        <Vids_Rab Type="Виды работ 1984г"/>
      </VidRab_Catalog>
      ${GetNode("Chapters",0,[],
      (()=>{
        let result=[]
        try{
        for (var i = 0; i < chapters.length; i++) {
          result.push(GetNode("Chapter",1,[
            GetAttr("Caption",GetValue(chapters, i, chapters_col_enum.Caption)),
            GetAttr("SysID",String(i+1))
          ],
          (()=>{
            let result=[]
            const char_positions=positions.filter((item) =>(item[positions_col_enum.Chapter]==i+1))
            for (var k = 0; k < char_positions.length; k++) {
              result.push(GetNode("Position",2,[
                GetAttr("Caption",GetValue(char_positions, k, positions_col_enum.Caption).replace("<","'").replace(">","'")),
                GetAttr("Number",GetValue(char_positions, k, positions_col_enum.Number)),
                GetAttr("Code",GetValue(char_positions, k, positions_col_enum.Code)),
                GetAttr("Units",GetValue(char_positions, k, positions_col_enum.Units)),
                GetAttr("SysID",String(k+1)),
                GetAttr("PriceLevel",GetValue(char_positions, k, positions_col_enum.PriceLevel)),
                GetAttr("DBComment",GetValue(char_positions, k, positions_col_enum.DBComment)),
                GetAttr("AuxMatCode",GetValue(char_positions, k, positions_col_enum.AuxMatCode)),
                GetAttr("AuxMat",GetValue(char_positions, k, positions_col_enum.AuxMat)),

                GetAttr("IndexCode",(()=>{
                  const ind=inds[Number(GetValue(char_positions, k, positions_col_enum.IndexCode))-1]
                  if (ind){
                    const code:string=ind[indexes_col_enum.Code]
                    if (code) return code
                    return ind[indexes_col_enum.Row]
                  }
                  return ''
                })()),
                GetAttr("Vr2001",(()=>{
                  const vr=vrs[Number(GetValue(char_positions, k, positions_col_enum.Vr2001))-1]
                  if (vr){
                    const id:string=vr[vrs_col_enum.ID]
                    if (id) return id
                    return vr[vrs_col_enum.Row]
                  }
                  return ''
                })()),
                GetAttr("NKB",GetValue(char_positions, k, positions_col_enum.NKB)),
                GetAttr("PNB",GetValue(char_positions, k, positions_col_enum.PNB)),
                GetAttr("NKI",GetValue(char_positions, k, positions_col_enum.NKI)),
                GetAttr("PKI",GetValue(char_positions, k, positions_col_enum.PKI)),
                GetAttr("NKR",GetValue(char_positions, k, positions_col_enum.NKR)),
                GetAttr("PKR",GetValue(char_positions, k, positions_col_enum.PKR)),
                GetAttr("Quantity",GetValue(char_positions, k, positions_col_enum.Quantity)),
                GetAttr("Options",GetValue(char_positions, k, positions_col_enum.Options)),
                GetAttr("SlaveRow",GetValue(char_positions, k, positions_col_enum.SlaveRow)),
              ],
              [
                GetNode("Quantity",3,[
                  GetAttr("Fx",`${GetValue(char_positions, k, positions_col_enum.Quantity)}`),
                  GetAttr("Result",GetValue(char_positions, k, positions_col_enum.Quantity)),
                ]),
                GetNode("PriceBase",3,[
                  GetAttr("OZ",GetValue(char_positions, k, positions_col_enum.BOZ)),
                  GetAttr("EM",GetValue(char_positions, k, positions_col_enum.BEM)),
                  GetAttr("ZM",GetValue(char_positions, k, positions_col_enum.BZM)),
                  GetAttr("MT",GetValue(char_positions, k, positions_col_enum.BMT)),
                ]),
                GetNode("PriceCurr",3,[
                  GetAttr("OZ",GetValue(char_positions, k, positions_col_enum.COZ)),
                  GetAttr("EM",GetValue(char_positions, k, positions_col_enum.CEM)),
                  GetAttr("ZM",GetValue(char_positions, k, positions_col_enum.CZM)),
                  GetAttr("MT",GetValue(char_positions, k, positions_col_enum.CMT)),
                ]),
                GetNode("Resources",3,[],
                (()=>{
                  let result=[]
                  try{
                    const ress=resources.filter((item) =>(item[resources_col_enum.Position]==GetValue(char_positions, k, positions_col_enum.Row)))
                  for (var l = 0; l < ress.length; l++){
                    result.push(GetNode(GetValue(ress,l,resources_col_enum.Type),4,
                    [
                      GetAttr("Caption",GetValue(ress, l, resources_col_enum.Caption)),
                      GetAttr("Code",GetValue(ress, l, resources_col_enum.Code)),
                      GetAttr("Units",GetValue(ress, l, resources_col_enum.Units)),
                      GetAttr("Quantity",GetValue(ress, l, resources_col_enum.Quantity)),
                      GetAttr("WorkClass",GetValue(ress, l, resources_col_enum.WorkClass)),
                      GetAttr("Options",GetValue(ress, l, resources_col_enum.Options)),
                      GetAttr("Attribs",GetValue(ress, l, resources_col_enum.Attribs)),
                      GetAttr("Mass",GetValue(ress, l, resources_col_enum.Mass)),
                      GetAttr("IndexCode",(()=>{
                        const ind=inds[Number(GetValue(ress, l, resources_col_enum.IndexCode))-1]
                        if (ind){
                          const code:string=ind[indexes_col_enum.Code]
                          if (code) return code
                          return ind[indexes_col_enum.Row]
                        }
                        return ''
                      })()),
                    ],
                    [
                      GetNode("PriceBase",5,[
                        GetAttr("Value",GetValue(ress, l, resources_col_enum.PriceBaseValue)),
                        GetAttr("ZM",GetValue(ress, l, resources_col_enum.PriceBaseZM)),
                      ]),
                      GetNode("PriceCurr",5,[
                        GetAttr("Value",GetValue(ress, l, resources_col_enum.PriceCurrValue)),
                        GetAttr("ZM",GetValue(ress, l, resources_col_enum.PriceCurrZM)),
                      ]),
                      GetNode("Tzm2022",5,[
                        GetAttr("WorkClass",GetValue(ress, l, resources_col_enum.TzmWorkClass)),
                        GetAttr("Quantity",GetValue(ress, l, resources_col_enum.TzmQuantity)),
                        GetAttr("Price",GetValue(ress, l, resources_col_enum.TzmPrice)),
                      ]),
                      GetNode("Transportation",5,[],
                      (()=>{
                        let result=[]
                          const items=trans.filter((item) =>(item[trans_col_enum.Resource]==GetValue(ress, l, resources_col_enum.Row)))
                          for (var m = 0; m < items.length; m++){
                            result.push(GetNode("Item",6,[
                              GetAttr("Caption",GetValue(items, m, trans_col_enum.Caption)),
                              GetAttr("Code",GetValue(items, m, trans_col_enum.Code)),
                              GetAttr("Units",GetValue(items, m, trans_col_enum.Units)),
                              GetAttr("PriceCurr",GetValue(items, m, trans_col_enum.PriceCurr)),
                              GetAttr("KC",GetValue(items, m, trans_col_enum.KC)),
                            ]))
                          }
                          return result
                      })())
                    ]
                    )
                    )
                  }
                  }catch{}
                  
                  return result
                })()
                ),
                GetNode("Koefficients",3,[],(()=>{
                  let result=[]
                  const smeta_ks=ks.filter((item) =>(item[k_col_enum.Position]==GetValue(char_positions, k, positions_col_enum.Row)))
                  for (var i = 0; i < smeta_ks.length; i++) {
                    result.push(GetNode("K",4,[
                      GetAttr('Caption',GetValue(smeta_ks,i,k_col_enum.Caption)),
                      GetAttr('Code',GetValue(smeta_ks,i,k_col_enum.Code)),
                      GetAttr('Level',GetValue(smeta_ks,i,k_col_enum.Level)),
                      GetAttr('Options',GetValue(smeta_ks,i,k_col_enum.Options)),
                      GetAttr('Value_EM',GetValue(smeta_ks,i,k_col_enum.Value_EM)),
                      GetAttr('Value_MT',GetValue(smeta_ks,i,k_col_enum.Value_MT)),
                      GetAttr('Value_OZ',GetValue(smeta_ks,i,k_col_enum.Value_OZ)),
                      GetAttr('Value_ZM',GetValue(smeta_ks,i,k_col_enum.Value_ZM)),
                    ]))
                  }
                  return result
                })()),
                GetNode("WorksList",3,[],
                (()=>{
                  let result=[]
                  try{
                    const poswls=wls.filter((item) =>(item[wls_col_enum.Position]==GetValue(char_positions, k, positions_col_enum.Row)))
                    for (var l = 0; l < poswls.length; l++){
                      result.push(GetNode("Work",4,[GetAttr("Caption",GetValue(poswls, l, wls_col_enum.Caption))]))
                    }
                  }catch{}
                  
                  return result
                })()
                )
              ]
              ))
          }
          return result})()
          ))
        }          
        }catch{}

        return result})()
      )}
      <ReportOptions Options="Grouping SeparateZakPodr" Kk="1" RangingGroups="Mch Mat">
        <RangingRates Mch="20" Mat="20"/>
      </ReportOptions>
    </Document>`
    return source
}