
import { ApolloClient, ApolloProvider, HttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from "@apollo/link-context";

import {  Pivot, PivotItem } from '@fluentui/react';
import { Convert } from './convert';
import { Iul } from './iul';
const uri = process.env.API_URI||"https://sd.xlsmeta.ru/api";
const httpLink = new HttpLink({ uri });
const cache = new InMemoryCache();

// Проверяем наличие токена и возвращаем заголовки в контекст
const authLink = setContext((_, { headers }) => {
  return { 
    headers: {
      ...headers,
      authorization: localStorage.getItem('token') || ''
      
    }
  };
});


// configure Apollo Client
const client = new ApolloClient({ 
  link: authLink.concat(httpLink), 
  cache,
  resolvers: {}, 
  connectToDevTools: true
});
export interface AppProps {
  title: string;
  isOfficeInitialized: boolean;
}

export const Main = () => { 

  return (
    <div>
      <Pivot aria-label="SmetaForte">
      <PivotItem headerText="ИУЛ Информационно-удостоверяющий лист(демоверсия)">
        <Iul demo={true}/>
      </PivotItem>
      <PivotItem
        headerText="Конвертер GGE-->XML"
        headerButtonProps={{
          'data-order': 1,
          'data-title': 'My Files Title',
        }}
      >
        <Convert/>
      </PivotItem>
     

    </Pivot>
    </div>
  );
  }