/* eslint-disable @typescript-eslint/no-unused-vars */
import {  PrimaryButton } from "@fluentui/react";
import { CreatePDF } from './PdfTools';
import { useMutation } from "@apollo/client";
import { CH_STATE } from "../gql/mutation";
import ym from "react-yandex-metrika";


export const DownloadFilePdf = props => {
  const doc =props.doc
  let state=props.state
  const [ChState] = useMutation(CH_STATE, { 
    variables:{
      text: `iul ${JSON.stringify(state)}`,

    },
  onCompleted: () => {
  }
      
    });
   const Download= async ()=>  {
    let file:string
    ChState()
    file =await CreatePDF(doc)
    file=file.substring(28)
    const b64toBlob = (b64Data, contentType='', sliceSize=512) => {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, {type: contentType});
      return blob;
    }
    //let blob = new Blob(atob(file), { type: 'application/pdf' });
    let blob =b64toBlob(file,'application/pdf')
    const filename = `${doc.Title}.pdf`
    //if (window.navigator.msSaveOrOpenBlob) {
        // Internet Explorer
    //    ;
    //    window.navigator.msSaveOrOpenBlob(blob, filename);

   // } else {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
        document.body.removeChild(link); //remove the link when done
        
  // }
    return;
}

    return (
        <>
        <div>
          <PrimaryButton 
            text={(doc.Demomark)?"Скачать ИУЛ образец в формате pdf":"Скачать ИУЛ в формате pdf"}
            onClick={async()=>{
              ym('reachGoal', 'iul', {awesomeParameter: 42});
              Download()
              }}/>
        </div>
        </>
        
      );
}


