
import { PrimaryButton } from "@fluentui/react";
import { IDownloadFile } from "../types/smeta_tables";
import { AsGrandXML } from './AsGrandXML';
import unicodeToWin1251 from "./unicodeToWin1251";
import { useMutation } from "@apollo/client";
import { CH_STATE } from "../gql/mutation";
export const DownloadFile = props => {
  const [ChState] = useMutation(CH_STATE, { 
    variables:{
      text: `convert ${props.source?props.source.filename:undefined}`,

    },
  onCompleted: () => {
  }
      
    });
   const Download= ()=>  {
    let file:IDownloadFile
    ChState()
    file =AsGrandXML(props.source)
    
    const win1251Array:any = unicodeToWin1251(file.xml);
    let blob = new Blob([win1251Array], { type: 'text/xml; charset=windows-1251;' });
    const filename = `${file.filename.replace(".xlsx","").replace(".gsfx","")}.xml`
    //if (window.navigator.msSaveOrOpenBlob) {
        // Internet Explorer
    //    ;
    //    window.navigator.msSaveOrOpenBlob(blob, filename);

   // } else {
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.download = filename;
        link.href = window.URL.createObjectURL(blob);
        link.click();
        document.body.removeChild(link); //remove the link when done
  // }
    return;
}
    return (
        <>
        <div>
          <br /> 
            <PrimaryButton 
              text="Скачать в формате Грандсмета(XML)"
              onClick={async()=>{
                Download()}} 
                disabled={props.disabled}
                />
              
        </div>
        </>
        
      );
}


