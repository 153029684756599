import React from 'react';
import './App.css';
import Pages from './pages';



function App() {

  return (
    <>
    
     <div className="App">
      <Pages/>
      </div>

    </>
   
    
  );
}

export default App;
