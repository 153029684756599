import React, { useState } from "react"
import { positions_col_enum } from "../types/smeta_tables";
import { Checkbox, Stack } from "@fluentui/react";
//import { useState } from "react";
//import { positions_col_enum } from "../types/smeta_tables";
let positions_table:any[][]
export const WithKMatSimple = props => {
  const [Checked,  toggleChecked ] = useState(()=>{
    try{
      if (props.source) positions_table=props.source['2']
      positions_table=positions_table.filter(item=>{
        if (item[positions_col_enum.AuxMat]){
          return true
        }
        return false
      })
      return (positions_table.length>0)
    }
    catch{
      
    }
    return false
  });
  

  const OnCheck= async ()=>{
    try{
      positions_table=props.source['2']
      positions_table.forEach((row)=>{
        if (!Checked){
          if ((String(row[positions_col_enum.Code]).startsWith("ГЭСНмр"))
            ||(String(row[positions_col_enum.Code]).startsWith("ФЕРмр"))
            ||(String(row[positions_col_enum.Code]).startsWith("ТЕРмр"))){
            row[positions_col_enum.AuxMatCode]='Приказ Минстроя России от 18.05.2022 №378/пр'
            row[positions_col_enum.AuxMat]='3'
            
          }else if  ((String(row[positions_col_enum.Code]).startsWith("ГЭСНм"))
            ||(String(row[positions_col_enum.Code]).startsWith("ФЕРм"))
            ||(String(row[positions_col_enum.Code]).startsWith("ТЕРм"))){
              row[positions_col_enum.AuxMatCode]='Приказ Минстроя России от 18.05.2022 №378/пр'
              row[positions_col_enum.AuxMat]='2'
          }
        }else{
          if ((String(row[positions_col_enum.Code]).startsWith("ГЭСНм"))
          ||(String(row[positions_col_enum.Code]).startsWith("ФЕРм"))
          ||(String(row[positions_col_enum.Code]).startsWith("ТЕРм"))
          ||(String(row[positions_col_enum.Code]).startsWith("ГЭСНмр"))
          ||(String(row[positions_col_enum.Code]).startsWith("ФЕРмр"))
          ||(String(row[positions_col_enum.Code]).startsWith("ТЕРмр")))
          {
            row[positions_col_enum.AuxMatCode]=''
            row[positions_col_enum.AuxMat]=''
          }
          
        }

        })
        toggleChecked(!Checked)

    }
    catch{
      

      }

    }
  
    return (
        <>
        
         <div>
          <br/>
            <Stack>
              <Stack.Item align="center">
                <Checkbox 
                label="Учесть вспомогательные ненормируемые материальные ресурсы" 
                checked={Checked}
                onChange={async()=> await OnCheck()}
                disabled={props.disabled}/>
                </Stack.Item>
               
               </Stack>
            
         </div>
        </>
        
      );
    }
  

