/* eslint-disable eqeqeq */
/* eslint-disable new-parens */
/* eslint-disable no-throw-literal */
import React, { useState } from "react"
export const UploadImage = (props:any) => {
    //const [Source, addSource] = useState('');
    const accept:string=props.accept?props.accept:"image/png, image/jpeg" 
    const [ErrMsg, addErrMsg] = useState('');
    const arrayreader = new FileReader();
    const   handleFileChange= async (e:any) => {
        try{
          e.preventDefault();            
              let file = e.target.files[0];
              //let filename: string = file.name
              //filename = filename.replace(/\.\w+$/, "")
                await arrayreader.abort
                await arrayreader.readAsArrayBuffer(file)
                arrayreader.onloadend = async () => {
                    try {
                        if (accept.includes(file.type)){
                          props.onUpload(arrayreader.result, file.type) 
                          
                        } else {
                         addErrMsg( 'Этот тип файла не поддерживается.')
                       }
                    } catch{
                      addErrMsg('Файл имеет неизвестную структуру.')
                      //props.onUpload(undefined) 
                     
                    }
                }                              
        }catch (err) {
          //addNewCaption("Создать")
          addErrMsg("Файл не загружен!")
          //props.onUpload(undefined) 
          //NoFileTrue()
      }
        
      }
    return (
        <>
         <input
            type="file"
            accept={accept}
            onChange={  (e) =>  handleFileChange(e)}             
        />
        {!(ErrMsg=='') && (
          <div>
            {ErrMsg}
          </div>
        )}

        </>
        )
    }