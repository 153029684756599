/* eslint-disable eqeqeq */
import { IInputTable, props_col_enum, props_row_enum } from "../types/smeta_tables";


const pjson = require('../../package.json');
export const asABC=(num:number)=>String.fromCharCode(num+64)

export const props_name=`${pjson.name}_p`
export const vrs_name=`${pjson.name}_vr`
export const inds_name=`${pjson.name}_i`
export const ks_name=`${pjson.name}_k`
export const wls_name=`${pjson.name}_wl`
export const lzs_name=`${pjson.name}_lz`
export const smetas_name=`${pjson.name}_0`
export const chapters_name=`${pjson.name}_1`
export const positions_name=`${pjson.name}_2`
export const resources_name=`${pjson.name}_3`
export const trans_name=`${pjson.name}_4`

 

export const SetValue=(row:any[], col:number, value:string)=>{
    while ((row.length-1)<col){
        row.push('')
    }
    row[col]=value
    return row
}
export const AddParamrow=(paramtable:IInputTable, templrow:any[], rownumber:number, value:string='', caption:string='', pref:string='')=>{
    let row =[...templrow]
    let rows=paramtable.rows
    const name=Object.keys(props_row_enum)[rownumber] 
    while ((rows.length)<(rownumber+1)){
        rows.push([...row])
    }
    SetValue(row,props_col_enum.Id,name)
    SetValue(row,props_col_enum.Caption,caption)
    SetValue(row,props_col_enum.Id,name)
    if (pref) value=`'${pref}${value}`
    SetValue(row,props_col_enum.Value,value)
    paramtable.rows[rownumber]=row
}

export const GetAttribute=(xelement:any,label:string,defval="")=>{
    try{
        
        let result= xelement.getAttributeNode(label).value
        if (result){
            const resnum=result.replace(/,/g,".")
            if (!isNaN(Number(resnum))) return resnum
            return result.replace(/"/g,"/")
        }
            
    }catch(e){
       console.log(e)
    }
     return defval
}

export const GetXMLNodeContent=(xelement:any, path:string, defval=""):string=>{
    try{
        const node=GetXMLNodes(xelement,path)[0]
        return GetContent(node,defval)
    }catch{}
    return defval
}
 const GetContent=(xelement:any, defval="")=>{
    try{
        
        let result= xelement.textContent
        if (result){
            const resnum=result.replace(/,/g,".")
            if (!isNaN(Number(resnum))) return resnum
            return result.replace(/"/g,"/")
        }
            
    }catch(e){
       console.log(e)
    }
     return defval
}

export const GetXMLNodes=(xml:any, path:string):any=>{
    let result=[xml]
    try{
        if (path.length==0) return undefined 
        
        let items:string[]=[]
        //if (path.includes('/')) items.concat(path.split("/"))
      items.push(...path.split("/"))   
        items.forEach((item)=>{                    
            result.forEach(itm=>{
                const children:any[]=[]
                const itms=itm.children
                for (var i = 0; i < itms.length; i++) {
                    if (itms[i]['nodeName']==item) children.push(itms[i])
                }
                //itms.forEach ((node)=>{
                 //   if (node['nodeName']==item) children.push(node)
              //  })
                result=[...children]
                //result=[...itm.getElementsByTagName(item)]
            })
        })
        return result
    }catch
    {
       
    }
    return undefined
} 


