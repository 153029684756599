import { gql } from "@apollo/client";

const SEND_CODE=gql`
mutation SendCode($email: String!, $code: String!) {
    SendCode(email: $email, code: $code)
  }
`
const CH_STATE=gql`
mutation ChState($text: String!) {
  ChState(text: $text)
}
`
const SIGNUP_USER = gql`
    mutation signUp($email: String!, $username: String!, $password: String!) {
       signUp(email: $email, username: $username, password: $password)
    }
  `;

  const SIGNIN_USER = gql`
  mutation signIn($email: String!, $password: String!) {
     signIn(email: $email, password: $password)
  }
`;
export{SEND_CODE, SIGNUP_USER, SIGNIN_USER,CH_STATE}